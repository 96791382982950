<template>
  <div>
    <div class="is-login">
      <div v-if="logged">
        <!-- // loadCart: khi thêm hoặc xóa thì cập nhật lại số lượng sp icon giỏ hàng  -->
        <header-top :loadCart="loadCart"></header-top>
      </div>
      <div class="d-flex">
        <div class="app-menu-left" v-if="logged && full_path != '/'">
          <menu-left></menu-left>
        </div>
        <div class="content-right" :class="{ 'full-screen': !logged }">
          <transition :name="logged ? 'slide-fade' : ''">
            <router-view @checkCartNumber="checkCartNumber" />
          </transition>
        </div>
      </div>
    </div>
    <div class="not-login"></div>
  </div>
</template>

<script type="text/javascript">
import {mapGetters, mapActions} from "vuex";
import ls from "local-storage";
import MenuLeft from "@/views/layout/MenuLeft";
import HeaderTop from "@/views/layout/HeaderTop";

export default {
  name: "App",

  components: {
    MenuLeft,
    HeaderTop,
  },

  props: {
    // xóa hoặc thêm giỏ hàng cập nhật lại số lượng trên icon
    // checkCartNumber: Boolean,
  },

  data() {
    return {
      showText: true,

      loadCart: 1,
    };
  },

  computed: {
    ...mapGetters("account", ["logged"]),
    ...mapGetters(["path", "full_path"]),
  },

  mounted() {
    
    // Logic mới
    if (this.isMobile()) {
      setTimeout(() => {
        let pathN = this.$route.path;
        let pathFull = 'https://m.hangve.com' + pathN;
        window.location.href = pathFull;
      }, 150);
    } else {
      this.checkLoged();
    }
  },

  methods: {
    ...mapActions("account", ["getAccountInfo"]),

    // thêm xóa sp giỏ hàng cập nhật lại số lượng 
    checkCartNumber(value) {
      this.loadCart = value
    },

    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },

    checkLoged() {
      let authen = ls.get("authen");
      if (!authen) {
        if (this.path !== "register") {
          this.$router.push({name: "account-login"});
        }
      } else {
        if (!this.logged) {
          this.getAccountInfo();
        }
      }
    },

    toggleMenuParent(value) {
      this.showText = value;
    },
  },

  watch: {
  }
};
</script>

<style type="text/css">
.app-menu-left {
  width: 65px;
  height: calc(100vh - 51px);
  background: #edf4fb;
}

.content-right {
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  width: 100%;
}

.content-right .home-index, .content-right .frame-content-common-2022 {
  height: 100%;
}

.full-screen {
  height: 100%;
  width: 100%;
}

.slide-fade-enter-active {
  transition: all 0.35s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.35s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
